<template>
  <v-app>
    <SideNavigation
      :top-items="topNavigationItems"
      :bottom-items="bottomNavigationItems"
      :display="displaySidebar"
      :helpLinks="helpLinks"
      @change="onSidebarChange"
    />
    <v-app-bar
      :app="$vuetify.breakpoint.mdAndUp"
      :clipped-left="$vuetify.breakpoint.mdAndUp"
      :fixed="$vuetify.breakpoint.mdAndUp"
      :scroll-off-screen="$vuetify.breakpoint.smAndDown"
      :scroll-threshold="50"
      class="flex-row justify-space-between"
      color="grey darken-4"
      dense
      dark
    >
      <v-app-bar-nav-icon
        v-if="$vuetify.breakpoint.smAndDown"
        @click.stop="displaySidebar = true"
      ></v-app-bar-nav-icon>
      <v-toolbar-title class="mr-12 align-center">
        <span class="title">{{ appName }}</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-row class="flex-row justify-end align-center">
        <v-btn
          class="mr-3"
          dark
          color="blue-grey lighten-2"
          small
          @click="goToGenerateExtensionSection"
        >
          {{ $t("settings.generate-extension.anchor-button") }}
        </v-btn>
        <GetScripts />
        <span class="pr-4">{{ profileInfoText }}</span>
        <!--PAYMENT V-DIALOGS-->
        <!-- choosing card-type -->
        <v-dialog v-model="paymentChooseDialog" max-width="500px">
          <!--<template v-slot:activator="{ on }">
            <v-btn dark color="light-blue lighten-1" small v-on="on">
              {{ $t("tariff-dialog.extend-button-text") }}
            </v-btn>
          </template>-->
          <v-card class="pa-6">
            <v-card-title class="px-0 py-2 text-h6 lighten-2">
              {{ $t("payments.choose-card-payment-type") }}
            </v-card-title>
            <v-radio-group v-model="choosedPaymentType" class="py-2" column>
              <v-radio
                :label="$t('payments.world-card-radio')"
                color="light-blue lighten-1"
                :value="paymentTypes.world"
              ></v-radio>
              <v-radio
                v-if="profileInfo"
                v-show="profileInfo.subscriptionStatus == 0"
                :label="$t('payments.russian-card-radio')"
                color="light-blue lighten-1"
                :value="paymentTypes.russian"
              ></v-radio>
            </v-radio-group>
            <v-row>
              <v-btn
                class="mr-3"
                dark
                color="light-blue lighten-1"
                small
                @click="choosePaymentCardType"
              >
                {{ $t("payments.choose") }}
              </v-btn>
              <v-btn
                dark
                color="light-blue lighten-1"
                small
                text
                @click="paymentChooseDialog = false"
              >
                {{ $t("payments.cancel") }}
              </v-btn>
            </v-row>
          </v-card>
        </v-dialog>
        <!-- end choosing card-type -->

        <!-- robocassa payment v-dialog -->
        <v-dialog v-model="robocassaDialog" v-="true" max-width="500px">
          <v-card>
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title
                    >{{ $t("tariff-dialog.current-tariff-heading") }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-subtitle
                  >{{ $t("tariff-dialog.tariff-name") }}:
                </v-list-item-subtitle>
                <v-list-item-subtitle class="black--text"
                  >{{
                    profileInfo && profileInfo.tariff
                      ? profileInfo.tariff.name
                      : ""
                  }}
                </v-list-item-subtitle>
              </v-list-item>
              <v-list-item>
                <v-list-item-subtitle
                  >{{ $t("tariff-dialog.tariff-price") }}:
                </v-list-item-subtitle>
                <v-list-item-subtitle class="black--text"
                  >{{
                    currentTariffPrice
                      ? `${currentTariffPrice} RUB`
                      : $t("tariff-dialog.free-tariff")
                  }}
                </v-list-item-subtitle>
              </v-list-item>
              <v-list-item>
                <v-list-item-subtitle
                  >{{ $t("tariff-dialog.extension-count") }}:
                </v-list-item-subtitle>
                <v-list-item-subtitle class="black--text"
                  >{{
                    profileInfo && profileInfo.tariff
                      ? profileInfo.tariff.maxExtensionCount
                      : ""
                  }}
                </v-list-item-subtitle>
              </v-list-item>
              <v-list-item>
                <v-list-item-subtitle
                  >{{ $t("tariff-dialog.valid-until") }}:
                </v-list-item-subtitle>
                <v-list-item-subtitle :class="validUntilColor + '--text'"
                  >{{
                    !profileInfo || !profileInfo.tariff
                      ? ""
                      : profileInfo.validUntil
                        | dateParse("YYYY.MM.DD HH:mm:ss")
                        | dateFormat("DD.MM.YYYY HH:mm")
                  }}
                </v-list-item-subtitle>
              </v-list-item>
            </v-list>
            <v-divider
              v-if="profileInfo && profileInfo.tariff && allTariffs.length"
            ></v-divider>
            <v-list
              v-if="profileInfo && profileInfo.tariff && allTariffs.length"
            >
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title
                    >{{ $t("tariff-dialog.new-tariff-heading") }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-subtitle
                  >{{ $t("tariff-dialog.tariff-name") }}:
                </v-list-item-subtitle>
                <v-list-item-subtitle class="black--text">
                  <v-select
                    :items="allTariffs"
                    item-value="id"
                    item-text="name"
                    return-object
                    outlined
                    dense
                    v-model="selectedTariff"
                    hide-details
                  ></v-select>
                </v-list-item-subtitle>
              </v-list-item>
              <v-list-item>
                <v-list-item-subtitle
                  >{{ $t("tariff-dialog.tariff-price") }}:
                </v-list-item-subtitle>
                <v-list-item-subtitle class="black--text"
                  >{{
                    selectedTariff && selectedTariff.price
                      ? `${selectedTariff.price} RUB`
                      : $t("tariff-dialog.free-tariff")
                  }}
                </v-list-item-subtitle>
              </v-list-item>
              <v-list-item>
                <v-list-item-subtitle
                  >{{ $t("tariff-dialog.extension-count") }}:
                </v-list-item-subtitle>
                <v-list-item-subtitle class="black--text"
                  >{{ selectedTariff ? selectedTariff.maxExtensionCount : "" }}
                </v-list-item-subtitle>
              </v-list-item>
              <v-list-item>
                <v-list-item-subtitle
                  >{{ $t("tariff-dialog.valid-until") }}:
                </v-list-item-subtitle>
                <v-list-item-subtitle :class="validUntilColor + '--text'"
                  >{{
                    selectedTariffValidUntil | dateFormat("DD.MM.YYYY HH:mm")
                  }}
                </v-list-item-subtitle>
              </v-list-item>
              <v-list-item
                class="d-flex flex-column justify-space-between align-start"
              >
                <v-btn
                  color="primary"
                  dark
                  :loading="paymentInProgress"
                  @click="startRobokassaPayment"
                  target="_blank"
                >
                  {{
                    isTariffExtending
                      ? $t("tariff-dialog.extend-button-text")
                      : $t("tariff-dialog.pay-button-text")
                  }}
                  ({{
                    (selectedTariff && selectedTariff.price
                      ? selectedTariff.price
                      : 0) + " RUB"
                  }})
                </v-btn>
              </v-list-item>
            </v-list>
          </v-card>
        </v-dialog>
        <!-- end robocassa payment v-dialog -->

        <!-- payproglobal payment v-dialog -->
        <v-dialog v-model="payproglobalDialog" v-="true" max-width="500px">
          <v-card>
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title
                    >{{ $t("tariff-dialog.current-tariff-heading") }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-subtitle
                  >{{ $t("tariff-dialog.tariff-name") }}:
                </v-list-item-subtitle>
                <v-list-item-subtitle class="black--text"
                  >{{
                    profileInfo && profileInfo.tariff
                      ? profileInfo.tariff.name
                      : ""
                  }}
                </v-list-item-subtitle>
              </v-list-item>
              <v-list-item>
                <v-list-item-subtitle
                  >{{ $t("tariff-dialog.tariff-price") }}:
                </v-list-item-subtitle>
                <v-list-item-subtitle class="black--text"
                  >{{
                    currentTariffPrice
                      ? `${currentTariffPrice} RUB`
                      : $t("tariff-dialog.free-tariff")
                  }}
                </v-list-item-subtitle>
              </v-list-item>
              <v-list-item>
                <v-list-item-subtitle
                  >{{ $t("tariff-dialog.extension-count") }}:
                </v-list-item-subtitle>
                <v-list-item-subtitle class="black--text"
                  >{{
                    profileInfo && profileInfo.tariff
                      ? profileInfo.tariff.maxExtensionCount
                      : ""
                  }}
                </v-list-item-subtitle>
              </v-list-item>
              <v-list-item>
                <v-list-item-subtitle
                  >{{ $t("tariff-dialog.valid-until") }}:
                </v-list-item-subtitle>
                <v-list-item-subtitle :class="validUntilColor + '--text'"
                  >{{
                    !profileInfo || !profileInfo.tariff
                      ? ""
                      : profileInfo.validUntil
                        | dateParse("YYYY.MM.DD HH:mm:ss")
                        | dateFormat("DD.MM.YYYY HH:mm")
                  }}
                </v-list-item-subtitle>
              </v-list-item>
            </v-list>
            <v-divider
              v-if="profileInfo && profileInfo.tariff && allTariffs.length"
            ></v-divider>
            <v-list
              v-if="profileInfo && profileInfo.tariff && allTariffs.length"
            >
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title
                    >{{ $t("tariff-dialog.new-tariff-heading") }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-subtitle
                  >{{ $t("tariff-dialog.tariff-name") }}:
                </v-list-item-subtitle>
                <v-list-item-subtitle class="black--text">
                  <v-select
                    :items="allTariffs"
                    item-value="id"
                    item-text="name"
                    return-object
                    outlined
                    dense
                    v-model="selectedTariff"
                    hide-details
                  ></v-select>
                </v-list-item-subtitle>
              </v-list-item>
              <v-list-item>
                <v-list-item-subtitle
                  >{{ $t("tariff-dialog.tariff-price") }}:
                </v-list-item-subtitle>
                <v-list-item-subtitle class="black--text"
                  >{{
                    selectedTariff && selectedTariff.price
                      ? `${selectedTariff.price} RUB`
                      : $t("tariff-dialog.free-tariff")
                  }}
                </v-list-item-subtitle>
              </v-list-item>
              <v-list-item>
                <v-list-item-subtitle
                  >{{ $t("tariff-dialog.extension-count") }}:
                </v-list-item-subtitle>
                <v-list-item-subtitle class="black--text"
                  >{{ selectedTariff ? selectedTariff.maxExtensionCount : "" }}
                </v-list-item-subtitle>
              </v-list-item>
              <v-list-item>
                <v-list-item-subtitle
                  >{{ $t("tariff-dialog.valid-until") }}:
                </v-list-item-subtitle>
                <v-list-item-subtitle :class="validUntilColor + '--text'"
                  >{{
                    selectedTariffValidUntil | dateFormat("DD.MM.YYYY HH:mm")
                  }}
                </v-list-item-subtitle>
              </v-list-item>
              <v-list-item
                class="d-flex flex-column justify-start align-start"
                column
              >
                <v-checkbox
                  color="primary"
                  v-model="autoPayment"
                  :label="$t('payments.auto-payment')"
                ></v-checkbox>
                <v-list-item-subtitle v-show="autoPayment">
                  {{ $t("payments.auto-payment-info") }}
                </v-list-item-subtitle>
              </v-list-item>
              <v-list-item
                class="d-flex flex-column justify-space-between align-start"
              >
                <v-btn
                  color="primary"
                  dark
                  :loading="paymentInProgress"
                  @click="startPayProGlobalPayment"
                  target="_blank"
                >
                  {{
                    isTariffExtending
                      ? $t("tariff-dialog.extend-button-text")
                      : $t("tariff-dialog.pay-button-text")
                  }}
                  ({{
                    (selectedTariff && selectedTariff.price
                      ? selectedTariff.price
                      : 0) + " RUB"
                  }})
                </v-btn>
                <v-list-item-subtitle
                  transition="scale-transition"
                  v-if="profileInfo"
                  v-show="
                    profileInfo.subscriptionStatus >= 1 && !isTariffExtending
                  "
                  class="pt-6"
                >
                  {{ $t("tariff-dialog.warn-change-subscription") }}
                </v-list-item-subtitle>
              </v-list-item>
            </v-list>
          </v-card>
        </v-dialog>
        <!-- end payproglobal payment v-dialog -->
        <!--END PAYMENT V-DIALOGS -->
        <LocaleSwitcher :langs="locales"></LocaleSwitcher>
        <v-dialog v-model="logoutDialog" max-width="380">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-export</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title class="headline"
              >{{ $t("logout-dialog.title") }}
            </v-card-title>
            <v-card-text>{{ $t("logout-dialog.text") }}</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red" @click="onLogoutClick" dark
                >{{ $t("logout-dialog.confirm-button-text") }}
              </v-btn>
              <v-btn color="primary" text @click="logoutDialog = false"
                >{{ $t("logout-dialog.cancel-button-text") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </v-app-bar>
    <RefreshSubscriptionBanner
      v-if="isSubscriptionExpires"
      :timeDifference="subscriptionTimeDifference"
    />
    <v-main>
      <div class="px-16">
        <div class="container" :style="mainContainerStyle">
          <slot />
        </div>
      </div>
    </v-main>
    <ScriptActionUpdateNotifier />
    <v-dialog
      v-model="termsAndConditions"
      max-width="600"
      :persistent="true"
      scrollable
    >
      <v-card>
        <v-card-title class="headline"
          >{{ $t("terms-and-conditions.title") }}
        </v-card-title>
        <v-card-text>
          <v-textarea v-html="$t('terms-and-conditions.text')"></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary darken-1"
            :disabled="termsAndConditionsInProgress"
            :loading="termsAndConditionsInProgress"
            @click="onAcceptTermsAndConditions"
            >{{ $t("terms-and-conditions.confirm-button-text") }}
          </v-btn>
          <v-btn
            color="grey darken-1"
            :disabled="termsAndConditionsInProgress"
            text
            @click="onLogoutClick"
            >{{ $t("terms-and-conditions.cancel-button-text") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ErrorSnackbar />
    <v-snackbar v-model="paymentError" :timeout="10000" color="red darken-3">
      {{ paymentErrorText }}
    </v-snackbar>
  </v-app>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import SideNavigation from "@/components/SideNavigation.vue";
import LocaleSwitcher from "@/components/LocaleSwitcher.vue";
import { Action, Getter } from "vuex-class";
import { MenuItem } from "@/models/MenuItem";
import { LOCALES } from "@/i18n/types";
import { ProfileInfo } from "@/models/ProfileInfo";
import { Preference } from "@/models/Preference";
import { PayProGlobalModel } from "@/models/PayProGlobalModel";
import { Tariff } from "@/models/Tariff";
import moment from "moment/moment";
import { Link } from "@/models/Link";
import RefreshSubscriptionBanner from "@/components/RefreshSubscriptionBanner.vue";
import ErrorSnackbar from "@/components/ErrorSnackbar.vue";
import GetScripts from "@/components/scripts/GetScripts.vue";
import { settingsHashRoutes } from "@/constants";
import ScriptActionUpdateNotifier from "@/components/ScriptActionUpdateNotifier.vue";

@Component({
  components: {
    SideNavigation,
    LocaleSwitcher,
    RefreshSubscriptionBanner,
    ErrorSnackbar,
    GetScripts,
    ScriptActionUpdateNotifier
  }
})
export default class DefaultLayout extends Vue {
  public goToGenerateExtensionSection() {
    this.$router.push({
      name: "Settings",
      hash: `#${settingsHashRoutes.generateExtension}`
    });
  }

  private logoutDialog = false;

  private displaySidebar = false;

  private onSidebarChange(value: boolean) {
    this.displaySidebar = value;
  }

  @Getter("tableOverflow")
  private tableOverflow!: number;

  @Getter("topNavigationItems")
  private topNavigationItems!: MenuItem[];

  @Getter("bottomNavigationItems")
  private bottomNavigationItems!: MenuItem[];

  @Action("disconnectGithub")
  private disconnectGithub!: () => Promise<void>;

  @Action("logout")
  private logout!: () => Promise<void>;

  @Action("acceptTermsAndConditions")
  private acceptTermsAndConditions!: () => Promise<void>;

  @Getter("appName")
  private appName!: string;

  @Getter("profileInfo")
  private profileInfo!: ProfileInfo;

  @Getter("tariffInfo")
  private tariffInfo!: Tariff;

  @Getter("appVersion")
  private appVersion!: string;

  @Action("fetchActionTypes")
  private fetchActionTypes!: () => Promise<void>;

  @Action("fetchProfileInfo")
  private fetchProfileInfo!: () => Promise<ProfileInfo>;

  @Action("fetchPreferences")
  private fetchPreferences!: () => Promise<Preference[]>;

  @Action("createRobokassaPayment")
  private createRobokassaPayment!: (tariffId: number) => Promise<string>;

  @Action("createPayProGlobalPayment")
  private createPayProGlobalPayment!: (
    model: PayProGlobalModel
  ) => Promise<string>;

  @Action("fetchTariffs")
  private fetchTariffs!: () => Promise<Tariff[]>;

  @Action("terminateSubscriptionPayProGlobal")
  private terminateSubscriptionPayProGlobal!: () => Promise<void>;

  @Getter("allTariffs")
  private allTariffs!: Tariff[];

  public get mainContainerStyle() {
    // TODO: check why types not working for $vuetify
    // eslint-disable-next-line
    // @ts-ignore
    if (this.$vuetify.breakpoint.name === "xl") {
      const maxWidth = 1785 + this.tableOverflow;

      return {
        maxWidth: `${maxWidth}px`
      };
    }
    return undefined;
  }

  private selectedTariff: Tariff | null = null;

  private locales: { value: string; caption: string }[] = LOCALES;

  private profileLoading = false;
  private tariffMenu = false;

  private get telegramProfileName() {
    return process.env.VUE_APP_TELEGRAM_PROFILE_NAME;
  }

  private get helpLinks(): Link[] {
    if (this.profileInfo?.tariff?.helpLinks == null) return [];

    return this.profileInfo.tariff.helpLinks
      .filter(l => !l.language || l.language == this.$i18n.locale)
      .sort((a, b) => a.order - b.order);
  }

  private get currentTariffPrice() {
    if (!this.profileInfo?.tariff) return "";

    return this.profileInfo.tariff.price;
  }

  private get profileInfoText() {
    // None = 0,
    // Active = 1,
    // Suspended = 2
    const subscriptionStatuses = [
      {
        alias: "None",
        text: ""
      },
      {
        alias: "Active",
        text: " • " + this.$t("payments.is-active-subscription")
      },
      {
        alias: "Suspended",
        text: " • " + this.$t("payments.is-active-suspended-subscription")
      }
    ];
    if (!this.profileInfo) return "";

    if (!this.profileInfo.tariff) return this.profileInfo.email;

    return (
      this.profileInfo.email +
      " • " +
      this.profileInfo.tariff.name +
      " (" +
      (this.profileInfo.tariff.usedExtensionCount || 0) +
      "/" +
      this.profileInfo.tariff.maxExtensionCount +
      ") " +
      " • " +
      this.formatDate(new Date(this.profileInfo.validUntil)) +
      subscriptionStatuses[this.profileInfo.subscriptionStatus].text
    );
  }

  private formatDate(date: Date): string {
    return this.$options.filters?.dateFormat(date, "DD.MM.YYYY");
  }

  private get isTariffExtending(): boolean {
    return this.profileInfo?.tariffId === this.selectedTariff?.id;
  }

  private get selectedTariffValidUntil(): Date {
    const currentValidUntil = new Date(this.profileInfo?.validUntil);
    const currentPrice = this.profileInfo?.tariff?.price ?? 0;

    const today = new Date();
    const fromDate = currentValidUntil < today ? today : currentValidUntil;
    const daysLeft = moment(fromDate).diff(today, "days");

    const moneyLeft =
      daysLeft > 0 && currentPrice != 0 ? (daysLeft * currentPrice) / 30 : 0;

    let daysToAdd = 30;

    if (!this.isTariffExtending) {
      const newTariffPrice = this.selectedTariff?.price ?? 0;

      daysToAdd +=
        moneyLeft && newTariffPrice ? (moneyLeft * 30) / newTariffPrice : 0;

      const newDate = moment(today)
        .add(daysToAdd, "days")
        .toDate();

      return newDate;
    }

    return moment(fromDate)
      .add(daysToAdd, "days")
      .toDate();
  }

  private get validUntilColor(): string {
    if (!this.profileInfo?.tariff) {
      return "rgba(16,143,210,0.66)";
    }
    if (new Date(this.profileInfo?.validUntil) > new Date()) {
      return "green";
    }
    return "red";
  }

  private termsAndConditions = false;
  private termsAndConditionsInProgress = false;

  private onAcceptTermsAndConditions() {
    this.termsAndConditionsInProgress = true;

    this.acceptTermsAndConditions()
      .then(() => {
        this.termsAndConditions = false;
      })
      .finally(() => {
        this.termsAndConditionsInProgress = false;
      });
  }

  //PAYMENT
  //flags
  private payproglobalDialog = false;
  private robocassaDialog = false;
  private paymentChooseDialog = false;
  private paymentInProgress = false;
  private autoPayment = true;
  private paymentError = false;
  private paymentErrorText = "";

  //data
  private paymentTypes = {
    world: "world_cards",
    russian: "russian_cards"
  };
  private choosedPaymentType = this.paymentTypes.world;

  //handlers
  private choosePaymentCardType(): void {
    if (this.choosedPaymentType == this.paymentTypes.russian)
      this.robocassaDialog = true;
    else this.payproglobalDialog = true;
  }

  private async startPayProGlobalPayment() {
    //if user has subscriptions terminate the subscription
    if (this.profileInfo.subscriptionStatus >= 1)
      await this.terminateSubscriptionPayProGlobal();

    this.createPayProGlobalPayment({
      tarrifId: this.selectedTariff?.id,
      autoPayment: this.autoPayment
    })
      .then(link => {
        window.open(link, "_blank");
      })
      .catch(e => {
        console.error(e);
        this.paymentError = true;
      })
      .finally(() => {
        this.payproglobalDialog = false;
        this.paymentChooseDialog = false;
        this.paymentInProgress = false;
      });
  }

  private async startRobokassaPayment() {
    if (!this.selectedTariff) return;

    this.paymentInProgress = true;

    this.createRobokassaPayment(this.selectedTariff?.id)
      .then(link => {
        window.open(link, "_blank");
      })
      .catch(e => {
        this.paymentError = true;
        this.paymentErrorText = e;
      })
      .finally(() => {
        this.tariffMenu = false;
        this.paymentInProgress = false;
      });
  }
  //PAYMENT END

  private onLogoutClick(): void {
    this.disconnectGithub().then(() =>
      this.logout().then(() => this.$router.push("/login"))
    );
  }

  private created() {
    if (localStorage.getItem("access-token")) {
      this.fetchActionTypes();

      this.fetchTariffs().then(() => {
        this.profileLoading = true;

        this.fetchProfileInfo().then(() => {
          this.profileLoading = false;

          if (!this.profileInfo?.isTermsAndConditionsAccepted) {
            this.termsAndConditions = true;
          }

          if (this.profileInfo?.tariff) {
            const selectedTariff = this.allTariffs.find(
              t => t.id == this.profileInfo.tariffId
            );

            if (selectedTariff) {
              this.selectedTariff = selectedTariff;
            } else {
              this.selectedTariff = this.allTariffs[0];
            }
          }
        });
      });
    }
    this.fetchPreferences();
  }

  private get subscriptionTimeDifference() {
    const expirationDate = new Date(this.profileInfo?.validUntil);
    const timestamp = Date.now();
    const timeDifference =
      (expirationDate.getTime() - timestamp) / (1000 * 3600 * 24);
    return timeDifference;
  }

  private get isSubscriptionExpires() {
    if (
      this.subscriptionTimeDifference < 5 &&
      this.profileInfo.subscriptionStatus != 1
    ) {
      return true;
    } else {
      return false;
    }
  }
}
</script>
